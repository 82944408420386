import React from 'react';
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './pages/login'
import UserList from './pages/user-management/user-list';
import UserAdd from './pages/user-management/user-add';
import UserDetail from './pages/user-management/user-detail';
import CategoryList from './pages/catgory-management/category-list';
import CategoryAdd from './pages/catgory-management/category-add';
import CategoryDetail from './pages/catgory-management/category-detail';
import LiveList from './pages/live-management/live-list';
import LiveAdd from './pages/live-management/live-add';
import LiveDetail from './pages/live-management/live-detail';
import BannerList from './pages/banner-management/banner-list';
import BannerAdd from './pages/banner-management/banner-add';
import BannerDetail from './pages/banner-management/banner-detail';
import VideoList from './pages/video-management/video-list';
import VideoAdd from './pages/video-management/video-add';
import VideoDetail from './pages/video-management/video-detail';
import NewsList from './pages/news-management/news-list';
import NewsAdd from './pages/news-management/news-add';
import NewsDetail from './pages/news-management/news-detail';
import NewsApprovalList from './pages/news-approval-management/news-approval-ist';
import NewsApprovalView from './pages/news-approval-management/news-approval-view';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin/user" element={<UserList />} />
        <Route path="/admin/user/add" element={<UserAdd />} />
        <Route path="/admin/user/edit/:id" element={<UserDetail />} />
        <Route path="/admin/category" element={<CategoryList />} />
        <Route path="/admin/category/add" element={<CategoryAdd />} />
        <Route path="/admin/category/edit/:id" element={<CategoryDetail />} />
        <Route path="/admin/live" element={<LiveList />} />
        <Route path="/admin/live/add" element={<LiveAdd />} />
        <Route path="/admin/live/edit/:id" element={<LiveDetail />} />
        <Route path="/admin/banner" element={<BannerList />} />
        <Route path="/admin/banner/add" element={<BannerAdd />} />
        <Route path="/admin/banner/edit/:id" element={<BannerDetail />} />
        <Route path="/admin/video" element={<VideoList />} />
        <Route path="/admin/video/add" element={<VideoAdd />} />
        <Route path="/admin/video/edit/:id" element={<VideoDetail />} />
        <Route path="/admin/journalist" element={<NewsList />} />
        <Route path="/admin/journalist/add" element={<NewsAdd />} />
        <Route path="/admin/journalist/edit/:id" element={<NewsDetail />} />
        <Route path="/admin/lead" element={<NewsApprovalList />} />
        <Route path="/admin/lead/view/:id" element={<NewsApprovalView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
