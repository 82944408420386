import React, { useState, useEffect } from 'react';
import {
    Grid,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import useUserMgtHook from '../../hooks/user-mgt-hook';
import CustomDialogBox from '../../components/custom-dialog-box';
import useDialogBoxHook from '../../hooks/dialog-box-hook';

export default function UserList() {

    const {
        user_list,
        actionDelete,
        fetchUser,
        actionChangeStatus
    } = useUserMgtHook()

    const columns = [
        { field: 'index', headerName: 'ลำดับ', maxWidth: 50, flex: 1 },
        { field: 'user_name', headerName: 'User Name', minWidth: 150, flex: 1 },
        { field: 'name', headerName: 'Name', minWidth: 250, flex: 1 },
        { field: 'role', headerName: 'ตำแหน่ง', minWidth: 100, flex: 1 },
        {
            field: "status",
            headerName: "",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={cellValues.row.status}
                                onClick={(event) => {
                                    handleStatusSwitch(event, cellValues);
                                }}
                            />
                        }
                        label=""
                    />
                </FormGroup>
            }
        },
        {
            field: "edit",
            headerName: "",
            sortable: false,
            maxWidth: 75,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <EditIcon />
                </IconButton>
            }
        },
        {
            field: "delete",
            headerName: "",
            sortable: false,
            maxWidth: 75,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleDeleteClick(event, cellValues);
                    }} >
                    <DeleteIcon />
                </IconButton>
            }
        }
    ];
    const {
        is_dialog_box_open,
        dialog_box_header,
        dialog_box_body,
        dialog_box_action,
        setDialogBoxOption
    } = useDialogBoxHook()

    const [selected_id, setSelectedId] = useState(0)
    const [selected_status, setSelectedStatus] = useState(false)

    const handleDialogClose = (event) => {
        setDialogBoxOption(
            false,
            "",
            "",
            ""
        )
        setSelectedId(0)
        setSelectedStatus(false)
    }

    const handleSubmitButton = async () => {
        let result
        if (dialog_box_action === 'change_status') {
            result = await actionChangeStatus(selected_id, selected_status)
        } else if (dialog_box_action === 'delete') {
            result = await actionDelete(selected_id)
        }
        alert(result.msg)
        if (result.result) {
            fetchUser()
            handleDialogClose()
        }
    }

    const handleStatusSwitch = (event, cellValues) => {
        event.preventDefault();
        let user_id = cellValues.row.id
        let user_name = cellValues.row.name
        let status = cellValues.row.status
        setSelectedId(user_id)
        setSelectedStatus(status)
        setDialogBoxOption(
            true,
            "เปลี่ยนสถานะ User",
            "คุณต้องการเปลี่ยนสถานะผู้ใช้งาน " + user_name + " หรือไม่",
            "change_status"
        )

    }

    const handleDeleteClick = (event, cellValues) => {
        event.preventDefault();
        let user_id = cellValues.row.id
        let user_name = cellValues.row.name
        setSelectedId(user_id)
        setDialogBoxOption(
            true,
            "ลบ User",
            "คุณต้องการลบช้อมูลผู้ใช้งาน " + user_name + " หรือไม่",
            "delete"
        )
    }

    const handleAddClick = (event) => {
        event.preventDefault();
        window.location.href = "/admin/user/add";
    }

    const handleEditClick = (event, cellValues) => {
        event.preventDefault();
        window.location.href = "/admin/user/edit/" + cellValues.row.id
    }

    return (
        <>
            <AdminMenu />
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"User Management"}
                    button_title={"Add User"}
                    handleBtnClick={handleAddClick}
                    is_main_page={true}
                />
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                            <DataGrid
                                style={{ backgroundColor: "#FFFFFF" }}
                                rows={user_list}
                                columns={columns}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <CustomDialogBox
                open={is_dialog_box_open}
                handleCancleButton={handleDialogClose}
                header={dialog_box_header}
                body={dialog_box_body}
                handleSubmitButton={handleSubmitButton}
            />
        </>
    )
}