import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const uploadPicture = async(formData) =>{
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'upload',
        formData,
        {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
}; 