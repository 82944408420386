import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    CssBaseline,
    TextField,
    Button,
    Alert
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../index.css'
import useFormDataHook from '../hooks/form-data-hook';
import useAuthHook from '../hooks/auth-hook';

const theme = createTheme();

export default function Login() {
    const [form_data, handelDataChange] = useFormDataHook()
    const { actionSignIn } = useAuthHook()
    const [isSuccess, setIsSuccess] = useState(true)

    const handleSubmit = async () => {
        let result = await actionSignIn(form_data)
        setIsSuccess(result)
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" style={{ marginTop: '8rem' }}>
                <CssBaseline />
                <Box className="login-box">
                    <Typography component="h1" variant="h5">
                        Thai Sport Plus Web Site Content Management
                    </Typography>
                    <Box
                        component="form"
                        // onSubmit={handleSubmit} 
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        {
                            !isSuccess ? <Alert severity="error">{"Username หรือ Password ผิด"}</Alert> : null
                        }
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={form_data?.username}
                            onChange={(e) => handelDataChange(e.target.value, 'username')}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={form_data?.password}
                            onChange={(e) => handelDataChange(e.target.value, 'password')}
                        />
                        <Button
                            // type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}