import React, { useState, useEffect } from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material'

export default function CustomDialogBox({ 
    open,
    header,
    body,
    handleSubmitButton,
    handleCancleButton,
}) {
    return (
        <Dialog
            open={open}
            onClose={handleCancleButton}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {header}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmitButton} variant="contained" color="success">
                    ตกลง
                </Button>
                <Button onClick={handleCancleButton} variant="contained" color="error">
                    ยกเลิก
                </Button>
            </DialogActions>
        </Dialog>
    )
}