import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getAllLiveStream = async () => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'streaming',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getLiveStreambyStreamID = async (id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'streaming/' + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addLiveStream = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.post(
        config.BASE_API + 'streaming',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updateLiveStream = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.put(
        config.BASE_API + 'streaming',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const deleteLiveStream = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.post(
      config.BASE_API + 'streaming/delete',
      obj,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+token
        }
      }
    )
  }

export const setActivateStream = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.post(
      config.BASE_API + 'streaming/set_active',
      obj,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+token
        }
      }
    )
  }