import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getVideo = async () => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'video',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getVideoDetails = async (video_id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'video/' + video_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addVideo = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'video',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updateVideo = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.put(
        config.BASE_API + 'video',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const setVideoActivate = async (obj) => {
    let token = LocalStorage.getToken();
    let header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(
        config.BASE_API + 'video/set_active',
        obj,
        {
            headers: header
        }
    )
}

export const deleteVideo = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'video/delete',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
} 