import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useFormDataHook from '../../hooks/form-data-hook';
import useAuthHook from '../../hooks/auth-hook';

export default function ChangePasswordModal({
    is_open,
    handleCloseModal,
}) {

    const [is_show_password, setIsShowPassword] = useState([])

    const handleShowPassword = (id) => {
        setIsShowPassword({
            ...is_show_password,
            [id]: !(is_show_password[id])
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [data, handelDataChange, clearData] = useFormDataHook()
    const {actionChangePassword} = useAuthHook()

    const handleAction = async () => {
        let result = await actionChangePassword(data)
        if(result.result){  
            alert(result.msg)
            handleCloseModal2()
        }else{
            alert(result.msg)
        }
    }

    const handleCloseModal2 = () => {
        clearData()
        handleCloseModal()
    }

    return (
        <>
            <Dialog
                open={is_open}
                onClose={handleCloseModal}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เปลี่ยน Password</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container rowSpacing={2} sx={{ mt: 1, pl: 5, pr: 5 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password เดิม</InputLabel>
                                <OutlinedInput
                                    type={is_show_password.old_password ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>handleShowPassword("old_password")}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {is_show_password.old_password  ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password เดิม"
                                    id="old_password"
                                    value={data.old_password}
                                    onChange={(e) => handelDataChange(e.target.value, 'old_password')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password ใหม่</InputLabel>
                                <OutlinedInput
                                    type={is_show_password.new_password ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>handleShowPassword("new_password")}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {is_show_password.new_password  ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password ใหม่"
                                    id="new_password"
                                    value={data.new_password}
                                    onChange={(e) => handelDataChange(e.target.value, 'new_password')}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">ยืนยัน Password ใหม่</InputLabel>
                                <OutlinedInput
                                    type={is_show_password.cfm_password ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>handleShowPassword("cfm_password")}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {is_show_password.cfm_password  ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="ยืนยัน Password ใหม่"
                                    id="cfm_password"
                                    value={data.cfm_password}
                                    onChange={(e) => handelDataChange(e.target.value, 'cfm_password')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAction}
                        variant="contained"
                        color="success"
                    >
                        ยืนยัน
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCloseModal2}
                        color="error"
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}