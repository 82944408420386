import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getAllUser = async () => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'users',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getUserByID = async (id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'users/' + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addUser = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'users',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updateUser = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.put(
        config.BASE_API + 'users',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const deleteUser = async (user_id) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.delete(
        config.BASE_API + 'users',
        // obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: {
                user_id: user_id
            }
        }
    )
}

export const setActivate = async (obj) => {
    let token = LocalStorage.getToken();
    let header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.patch(
        config.BASE_API + 'users',
        obj,
        {
            headers: header
        }
    )
}