import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    CardActions,
    Button
} from '@mui/material';
import useUserMgtHook from '../../hooks/user-mgt-hook';

export default function UserForm({ mode, button_title }) {
    const { id } = useParams();
    const { actionSave, fetchUserDetail, form_data, handelDataChange } = useUserMgtHook()

    const handleSaveButton = async () => {
        let result = await actionSave(mode)
        alert(result.msg)

        if (result.result) {
            window.location.href = "/admin/user";
        }
    }

    useEffect(() => {
        const asyncFn = async () => {
            if (mode === 'edit') {
                await fetchUserDetail(id)
            }
        }
        asyncFn()
    }, [])

    return (
        <>
            <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="first_name"
                                label="ชื่อ"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.first_name}
                                onChange={(e) => handelDataChange(e.target.value, 'first_name')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="last_name"
                                label="นามสกุล"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.last_name}
                                onChange={(e) => handelDataChange(e.target.value, 'last_name')}
                            />
                        </Grid>
                        {
                            mode === 'add' ?
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="username"
                                            label="Username"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.username}
                                            onChange={(e) => handelDataChange(e.target.value, 'username')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            variant="outlined"
                                            type="password"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.password}
                                            onChange={(e) => handelDataChange(e.target.value, 'password')}
                                        />
                                    </Grid>
                                </>
                                : null
                        }
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item ></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveButton}
                            >
                                {button_title}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { window.location.href = "/admin/user"; }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    )
}