import React, { useState, useEffect } from 'react';
import {
    Container,
} from '@mui/material';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import BannerForm from '../../components/form/banner-form';

export default function BannerAdd() {
    return(
        <>
            <AdminMenu/>
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Add Banner"}
                    is_main_page={false}
                />
                <BannerForm
                    mode={'add'}
                    button_title={'Add'}
                />
            </Container>
        </>
    )
}