import React, { useState, useEffect } from 'react';
import {
    getAllUser,
    addUser,
    getUserByID,
    updateUser,
    deleteUser,
    setActivate
} from '../services/user-mgt-service';
import useFormDataHook from './form-data-hook';

export default function useUserMgtHook(path) {

    const [user_list, setUserList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    const fetchUser = async () => {
        try {
            let res = await getAllUser()
            let temp_user_list = []
            let all_user = res.data.data
            all_user.map((item, index) => {
                temp_user_list.push({
                    key: index + 1,
                    id: item.user_id,
                    index: index + 1,
                    user_name: item.username,
                    name: item.first_name + " " + item.last_name,
                    category: item.category_name,
                    role: item.role_name,
                    status: item.active
                })
            })
            setUserList(temp_user_list)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserDetail = async (user_id) => {
        try {
            setData({
                ...form_data,
                'user_id': user_id,
                'first_name': '',
                'last_name': '',
                'username': '',
                'password': '',
                'name': '',
                'category_id': 0,
                'role_id': 0
            })
            let res = await getUserByID(user_id)
            let user_data = res.data.data[0]
            setData({
                ['user_id']: user_data.user_id,
                ['first_name']: user_data.first_name,
                ['last_name']: user_data.last_name,
                ['username']: user_data.username,
                ['password']: user_data.password,
                ['name']: user_data.name,
                ['category_id']: user_data.category_id,
                ['role_id']: user_data.role_id,
            })
        } catch (error) {
            console.log(error);
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.username === '' || form_data.username === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูล username \n กรุณากรอกข้อมูล username"
            }
        } else if (form_data.password === '' || form_data.password === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูล password \n กรุณากรอกข้อมูล password"
            }
        } else if (form_data.first_name === '' || form_data.first_name === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูล ชื่อ \n กรุณากรอกข้อมูล ชื่อ"
            }
        } else if (form_data.last_name === '' || form_data.last_name === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูล นามสกุล \n กรุณากรอกข้อมูล นามสกุล"
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        let email = "test@gmail.com"
        let category_id = 1
        let role_id = 3
        if (mode === 'edit') {
            email = form_data.email
            category_id = form_data.category_id
            role_id = form_data.role_id
        }

        let obj = {
            username: form_data.username,
            name: form_data.first_name + " " + form_data.last_name,
            first_name: form_data.first_name,
            last_name: form_data.last_name,
            password: form_data.password,
            email: email,
            category_id: category_id,
            role_id: role_id
        }

        try {
            if (mode === 'add') {
                await addUser(obj)
                result = {
                    'result': true,
                    'msg': "เพิ่มผู้ใช้งานสำเร็จ"
                }
            } else {
                obj.user_id = form_data.user_id
                await updateUser(obj)

                result = {
                    'result': true,
                    'msg': "แก้ไขผู้ใช้งานสำเร็จ"
                }
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }

        }
        console.log(result);
        return result
    }

    const actionDelete = async (user_id) => {
        let result
        try {
            await deleteUser(user_id)
            result = {
                'result': true,
                'msg': "ลบผู้ใช้งานสำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    const actionChangeStatus = async (user_id, status) => {
        let result
        try {
            await setActivate({
                user_id: user_id,
                active: !status
            })
            result = {
                'result': true,
                'msg': "เปลี่ยนสถานะผู้ใช้งานสำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }


    useEffect(() => {
        fetchUser()
    }, [])

    return {
        user_list,
        fetchUserDetail,
        form_data,
        handelDataChange,
        actionSave,
        actionDelete,
        fetchUser,
        actionChangeStatus
    }
}