
import {
    Grid,
    Typography,
    Button
} from '@mui/material';

export default function BoFTitle({ title, button_title, handleBtnClick, is_main_page }) {
    return (
        <Grid container>
            <Grid item xs>
                <Typography variant='h4' style={{ fontWeight: 'bold' }}>{title}</Typography>
            </Grid>
            <Grid item >
                {
                    is_main_page ?
                        <Button
                            variant="contained"
                            onClick={handleBtnClick}
                        >
                            {button_title}
                        </Button>
                    : null
                }
            </Grid>
        </Grid>
    )
}