import React, { useState, useEffect } from 'react';
import {
    Container,
} from '@mui/material';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import LiveForm from '../../components/form/live-form';

export default function LiveDetail() {
    return(
        <>
            <AdminMenu/>
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Live Stream Detail"}
                    is_main_page={false}
                />
                <LiveForm
                    mode={'edit'}
                    button_title={'Edit'}
                />
            </Container>
        </>
    )
}