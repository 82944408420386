import React, { useState, useEffect } from 'react';
import { addBanner, deleteBanner, getBannerDetails, getBannerList, updateBanner } from '../services/banner-service';
import useFormDataHook from './form-data-hook';
import { uploadPicture } from '../services/upload-service';

export default function useBannerMgtHook(path) {
    const [banner_list, setBannerList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    useEffect(() => {
        fetchBanner()
    }, [])

    const fetchBanner = async () => {
        try {
            let res = await getBannerList()
            let temp_list = []
            let all_banner = res.data.data
            all_banner.map((item, index) => {
                temp_list.push({
                    id: item.banner_id,
                    index: index + 1,
                    banner_subject: item.banner_subject,
                    banner_body: item.banner_body,
                    banner_thumbnail: item.banner_thumbnail,
                    banner_image: item.banner_image,
                    banner_link: item.banner_link,
                    active: item.active
                })
            })
            setBannerList(temp_list)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBannerDetail = async (id) => {
        try {
            setData({
                ...form_data,
                "banner_id": 0,
                "banner_subject": "",
                "banner_body": "",
                "banner_thumbnail": "",
                "banner_image": "",
                "banner_link": "",
                "active": true
            })
            let res = await getBannerDetails(id)
            let banner_data = res.data.data[0]
            setData({
                ['banner_id']: banner_data.banner_id,
                ['banner_subject']: banner_data.banner_subject,
                ['banner_body']: banner_data.banner_body,
                ['banner_thumbnail']: banner_data.banner_thumbnail,
                ['banner_image']: banner_data.banner_image,
                ['banner_link']: banner_data.banner_link,
                ['active']: banner_data.active,
            })
        } catch (error) {
            console.log(error);
        }
    }

    const onFileThumbnailChange = async (event, picture_id) => {
        if (event.target.files[0] !== undefined) {
            const formData = new FormData();
            formData.append(
                "file",
                event.target.files[0],
                event.target.files[0].name
            );
            try {
                let res_result = await uploadPicture(formData)
                handelDataChange(res_result.data.path, picture_id)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.banner_subject === '' || form_data.banner_subject === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลหัวข้อ Banner \n กรุณากรอกข้อมูลหัวข้อ Banner"
            }
        } else if (form_data.banner_body === '' || form_data.banner_body === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลเนื้อหา Banner \n กรุณากรอกข้อมูลเนื้อหา Banner"
            }
        } else if (form_data.banner_image === '' || form_data.banner_image === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูปสำหรับอุปกรณ์ทั่วไป \n กรุณาเพิ่มรูปสำหรับอุปกรณ์ทั่วไป"
            }
        } else if (form_data.banner_link === '' || form_data.banner_link === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูปสำหรับ Tablet \n กรุณาเพิ่มรูปสำหรับ Tablet"
            }
        } else if (form_data.banner_thumbnail === '' || form_data.banner_thumbnail === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูปสำหรับมือถือ \n กรุณาเพิ่มรูปสำหรับมือถือ"
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        if (result.result) {
            let obj = {
                banner_subject: form_data.banner_subject,
                banner_body: form_data.banner_body,
                banner_thumbnail: form_data.banner_thumbnail,
                banner_image: form_data.banner_image,
                banner_link: form_data.banner_link,
                active: 0
            }
            try {
                if (mode === 'add') {
                    await addBanner(obj)
                    result = {
                        'result': true,
                        'msg': "เพิ่ม Banner สำเร็จ"
                    }
                } else {
                    obj.banner_id = form_data.banner_id
                    obj.active = form_data.active
                    await updateBanner(obj)
                    result = {
                        'result': true,
                        'msg': "แก้ไข Banner สำเร็จ"
                    }
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
                }
            }
        }
        return result
    }

    const actionChangeStatus = async (banner_data) => {
        let result
        try {
            let obj = {
                'banner_id': banner_data.id,
                'active': !banner_data.active,
                'banner_subject': banner_data.banner_subject,
                'banner_body': banner_data.banner_body,
                'banner_thumbnail': banner_data.banner_thumbnail,
                'banner_image': banner_data.banner_image,
                'banner_link': banner_data.banner_link,
            }
            console.log(obj);
            await updateBanner(obj)
            result = {
                'result': true,
                'msg': "เปลี่ยนสถานะ Banner สำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    const actionDelete = async(banner_id) => {
        let result
        try {
            let obj = {
                'banner_id': banner_id
            }
            // console.log(obj);
            await deleteBanner(obj)
            result = {
                'result': true,
                'msg': "ลบ Banner สำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    return {
        banner_list,
        fetchBanner,
        fetchBannerDetail,
        actionSave,
        form_data,
        handelDataChange,
        onFileThumbnailChange,
        actionChangeStatus,
        actionDelete,
    }
}