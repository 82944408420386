import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getAllCategorys = async () => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'categorys?active=true',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getDetailsCategorys = async (category_id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'categorys/' + category_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addCategory = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'categorys',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const editCategory = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.put(
        config.BASE_API + 'categorys',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const deleteCategory = async (obj) => {
    let token = LocalStorage.getToken();
    console.log(token);
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsIm5hbWUiOiJhZG1pbiIsImZpcnN0X25hbWUiOiJhZG1pbiIsImxhc3RfbmFtZSI6ImFkbWluIiwicm9sZV9pZCI6MSwiaWF0IjoxNjY4NjE4Njk5LCJleHAiOjE2Njg3MDUwOTl9.dah8UjEttEPMw4RIGQwmEiF282r7J1uABX95Cn7VSac"
    return axios.post(
      config.BASE_API + 'categorys/delete',
      obj,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+token
        },
      }
    )
  }