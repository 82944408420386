import React, { useState, useEffect } from 'react';
import { addLiveStream, getAllLiveStream, getLiveStreambyStreamID, updateLiveStream, setActivateStream, deleteLiveStream } from '../services/live-mgt-service';
import useFormDataHook from './form-data-hook';
import { uploadPicture } from '../services/upload-service';

export default function useLiveMgtHook(path) {

    const [live_list, setLiveList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    useEffect(() => {
        fetchLiveStream()
    }, [])

    const fetchLiveStream = async () => {
        try {
            let res_data = await getAllLiveStream()
            let all_stream = res_data.data.data
            let temp_stream_list = []
            all_stream.map((item, index) => {
                temp_stream_list.push({
                    id: item.streaming_id,
                    index: index + 1,
                    streaming_name: item.streaming_name,
                    active: item.active
                })
            })
            setLiveList(temp_stream_list)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchLiveStreamDetail = async (id) =>{
        try {
            setData({
                ...form_data,
                'streaming_id': 0,
                'category_id': 1,
                'streaming_name': "",
                'streaming_desc': "",
                'streaming_thumbnail': "",
                'streaming_url': "",
                'streaming_tags': "",
                'active': false
            })
            let res = await getLiveStreambyStreamID(id)
            let data = res.data.data[0]
            setData({
                ['streaming_id']: data.streaming_id,
                ['category_id']: data.category_id,
                ['streaming_name']: data.streaming_name,
                ['streaming_desc']: data.streaming_desc,
                ['streaming_thumbnail']: data.streaming_thumbnail,
                ['streaming_url']: data.streaming_url,
                ['streaming_tags']: data.streaming_tags,
                ['active']: data.active
            })
        } catch (error) {
            console.log(error);            
        }
    }

    const onFileThumbnailChange = async (event) => {
        if (event.target.files[0] !== undefined) {
            const formData = new FormData();
            formData.append(
                "file",
                event.target.files[0],
                event.target.files[0].name
            );
            try {
                let res_result = await uploadPicture(formData)
                handelDataChange(res_result.data.path, 'streaming_thumbnail')
            } catch (error) {
                console.log(error);
            }
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.streaming_name === '' || form_data.streaming_name === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลชื่อ Live \n กรุณากรอกข้อมูลชื่อ Live"
            }
        }else if (form_data.streaming_tags === '' || form_data.streaming_tags === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลช่องทางการ Live \n กรุณากรอกข้อมูลช่องทางการ Live"
            }
        }else if (form_data.streaming_thumbnail === '' || form_data.streaming_thumbnail === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูปหน้าแรก \n กรุณาเพิ่มรูปหน้าแรก"
            }
        } else if (
            (form_data.streaming_desc === '' || form_data.streaming_desc === undefined) &&
            (form_data.streaming_url === '' || form_data.streaming_url === undefined)
        ) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่ม link live \n กรุณาเพิ่ม link live "
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        let obj = {
            category_id: 1,
            streaming_name: form_data.streaming_name,
            streaming_desc: form_data.streaming_desc,
            streaming_thumbnail: form_data.streaming_thumbnail === undefined ? '' : form_data.streaming_thumbnail,
            streaming_url: form_data.streaming_url === undefined ? '' : form_data.streaming_url,
            streaming_tags: form_data.streaming_tags,
        }
        if(result.result){
            try {
                if (mode === 'add') {
                    await addLiveStream(obj)
                    result = {
                        'result': true,
                        'msg': "เพิ่ม Live Stream สำเร็จ"
                    }
                } else {
                    obj.streaming_id = form_data.streaming_id
                    obj.active = form_data.active
                    console.log(obj);
                    await updateLiveStream(obj)
                    result = {
                        'result': true,
                        'msg': "แก้ไข Live Stream สำเร็จ"
                    }
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
                }
            }

        }
        return result
    }

    const actionChangeStatus = async (streaming_id, status) =>{
        let result
        console.log('status', status);
        try {
            await setActivateStream({
                streaming_id: streaming_id,
                active: !status
            })
            result = {
                'result': true,
                'msg': "เปลี่ยนสถานะ Live Stream"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    const actionDelete = async(streaming_id) =>{
        let result
        try {
            let obj = {
                'streaming_id': streaming_id,
            }
            await deleteLiveStream(obj)
            result = {
                'result': true,
                'msg': "ลบ Live Stream สำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    return {
        live_list,
        form_data,
        fetchLiveStream,
        fetchLiveStreamDetail,
        handelDataChange,
        onFileThumbnailChange,
        actionSave,
        actionChangeStatus,
        actionDelete
    }
}