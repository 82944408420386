import React, { useState, useEffect } from 'react';
import {
    Grid,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import CustomDialogBox from '../../components/custom-dialog-box';
import useDialogBoxHook from '../../hooks/dialog-box-hook';
import useNewsMgtHook from '../../hooks/news-mgt-hook';

export default function NewsList() {

    const columns = [
        { field: 'index', headerName: 'ลำดับ', maxWidth: 50, flex: 1 },
        { field: 'news_subject', headerName: 'พาดหัวข่าว', minWidth: 450, flex: 1 },
        { field: 'news_status', headerName: 'สถานะการ Approve', minWidth: 200, flex: 1 },
        { field: 'category_name', headerName: 'สมาคม', minWidth: 150, flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <EditIcon />
                </IconButton>
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleDeleteClick(event, cellValues);
                    }} >
                    <DeleteIcon />
                </IconButton>
            }
        }
    ];

    const {
        news_list,
        fetchNews,
        actionDelete
    } = useNewsMgtHook()

    const {
        is_dialog_box_open,
        dialog_box_header,
        dialog_box_body,
        dialog_box_action,
        setDialogBoxOption
    } = useDialogBoxHook()

    const [selected_id, setSelectedId] = useState(0)

    const handleAddClick = (event) => {
        event.preventDefault();
        window.location.href = "/admin/journalist/add"
    }

    const handleEditClick = (event, cellValues) => {
        event.preventDefault();
        window.location.href = "/admin/journalist/edit/" + cellValues.row.id
    }

    const handleDeleteClick = (event, cellValues) => {
        event.preventDefault();
        let news_id = cellValues.row.id
        let news_subject = cellValues.row.news_subject
        setSelectedId(news_id)
        setDialogBoxOption(
            true,
            "ลบข่าว",
            "คุณต้องการลบช้อมูลข่าว " + news_subject + " หรือไม่",
            "delete"
        )
    }

    const handleDialogClose = (event) => {
        setDialogBoxOption(
            false,
            "",
            "",
            ""
        )
        setSelectedId(0)
    }

    const handleSubmitButton = async () => {
        let result
        // if (dialog_box_action === 'change_status') {
        //     result = await actionChangeStatus(selected_data)
        // } else if (dialog_box_action === 'delete') {
        result = await actionDelete(selected_id)
        // }
        alert(result.msg)
        if (result.result) {
            fetchNews()
            handleDialogClose()
        }
    }

    return (
        <>
            <AdminMenu />
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"News Management"}
                    button_title={"Add News"}
                    handleBtnClick={handleAddClick}
                    is_main_page={true}
                />
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                            <DataGrid
                                style={{ backgroundColor: "#FFFFFF" }}
                                rows={news_list}
                                columns={columns}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <CustomDialogBox
                open={is_dialog_box_open}
                handleCancleButton={handleDialogClose}
                header={dialog_box_header}
                body={dialog_box_body}
                handleSubmitButton={handleSubmitButton}
            />
        </>
    )
}