import React, { useState, useEffect } from 'react';
import useFormDataHook from './form-data-hook';
import localStorage from '../utils/local-storage';
import { jwtDecode } from "jwt-decode";
import { addNews, approveNews, deleteNews, getNewsbyCreatedID, getNewsbyNewsID, updateNews } from '../services/news-service';
import { uploadPicture } from '../services/upload-service';

export default function useNewsMgtHook(path) {
    const [news_list, setNewsList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    useEffect(() => {
        fetchNews()
        setData({
            ...form_data,
            'category_id': 0
        })
    }, [])


    const fetchNews = async () => {
        let token = localStorage.getToken()
        var decoded = jwtDecode(token);
        console.log(decoded);
        try {
            let res = await getNewsbyCreatedID(decoded.id)
            let all_news = res.data.data
            let temp_news_list = []
            console.log(all_news);
            all_news.map((item, index) => {
                temp_news_list.push({
                    id: item.news_id,
                    index: index + 1,
                    news_subject: item.news_subject,
                    news_status: item.news_status,
                    approved_name: item.approved_name,
                    category_name: item.category_name
                })
            })
            setNewsList(temp_news_list)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchNewsDetail = async (id) => {
        try {
            setData({
                ...form_data,
                "news_id": 0,
                "category_id": "",
                "news_subject": "",
                "news_body": "",
                "news_thumbnail": "",
                "news_image": "",
            })
            let res = await getNewsbyNewsID(id)
            let news_data = res.data.data[0]
            setData({
                ['news_id']: news_data.news_id,
                ['category_id']: news_data.category_id,
                ['news_subject']: news_data.news_subject,
                ['news_body']: news_data.news_body,
                ['news_thumbnail']: news_data.news_thumbnail,
                ['news_image']: news_data.news_image,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const onFileThumbnailChange = async (event, picture_id) => {
        if (event.target.files[0] !== undefined) {
            const formData = new FormData();
            formData.append(
                "file",
                event.target.files[0],
                event.target.files[0].name
            );
            try {
                let res_result = await uploadPicture(formData)
                if (picture_id !== "news_image_body") {
                    handelDataChange(res_result.data.path, picture_id)
                } else {
                    let news_body = form_data.news_body === undefined ? '' : form_data.news_body
                    let image_tag = "<p><img src=\"" + res_result.data.path + "\" alt=\"\" width=\"300px\"></p>"
                    handelDataChange(news_body + image_tag, 'news_body')
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.news_subject === '' || form_data.news_subject === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลพาดหัวข่าว \n กรุณากรอกข้อมูลหัวข้อพาดหัวข่าว"
            }
        } else if (form_data.category_id === 0 || form_data.category_id === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เลือกสมาคม \n กรุณาเลือกสมาคม"
            }
        } else if (form_data.news_thumbnail === '' || form_data.news_thumbnail === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูปหน้าแรก \n กรุณาเพิ่มรูปหน้าแรก"
            }
        } else if (form_data.news_image === '' || form_data.news_image === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูป Banner ข่าว \n กรุณาเพิ่มรูป Banner ข่าว"
            }
        } else if (form_data.news_body === '' || form_data.news_body === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มเนื้อหาข่าว \n กรุณาเพิ่มเนื้อหาข่าว"
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        if (result.result) {
            let obj = {
                category_id: form_data.category_id,
                news_subject: form_data.news_subject,
                news_body: form_data.news_body,
                news_thumbnail: form_data.news_thumbnail,
                news_image: form_data.news_image,
                news_status: "รอการอนุมัติ",
                news_tags: "xxx"
            }
            try {

                if (mode === 'add') {
                    await addNews(obj)
                    result = {
                        'result': true,
                        'msg': "เพิ่มข่าวสำเร็จ"
                    }
                } else if (mode === 'edit') {
                    obj.news_id = form_data.news_id
                    await updateNews(obj)
                    result = {
                        'result': true,
                        'msg': "แก้ไขข่าวสำเร็จ"
                    }
                } else {
                    if(form_data.news_status === 0 || form_data.news_status === undefined){
                        result = {
                            'result': false,
                            'msg': "คุณยังไม่ได้เลือกสถานะข่าว \n กรุณาเลือกสถานะข่าว"
                        }
                    }else{
                        let news_status = ''
                        switch (form_data.news_status) {
                            case 1:
                                news_status = "อนุมัติ"
                                break;
                            case 2:
                                news_status = "ไม่อนุมัติ แก้ไขพาดหัวข่าว"
                                break;
                            case 3:
                                news_status = "ไม่อนุมัติ แก้ไขเนื้อหาข่าว"
                                break;
                            case 4:
                                news_status = "ไม่อนุมัติ แก้ไขรูปหน้าปก"
                                break;
                            case 5:
                                news_status = "ไม่อนุมัติ แก้ไขรูปหน้าแรก"
                                break;
                            default:
                                break;
                        }
                        obj.news_id = form_data.news_id
                        obj.news_status = news_status
                        await approveNews(obj)
                        result = {
                            'result': true,
                            'msg': "เปลี่ยนสถานะข่าวสำเร็จ"
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
                }
            }
        }
        return result
    }

    const actionDelete = async (id) => {
        let result
        try {
            let obj = {
                'news_id': id
            }
            // console.log(obj);
            await deleteNews(obj)
            result = {
                'result': true,
                'msg': "ลบข่าวสำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    return {
        news_list,
        fetchNews,
        form_data,
        handelDataChange,
        onFileThumbnailChange,
        actionSave,
        fetchNewsDetail,
        actionDelete,
    }
}