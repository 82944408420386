import React, { useState, useEffect } from 'react';
import useFormDataHook from './form-data-hook';
import { uploadPicture } from '../services/upload-service';
import { addVideo, deleteVideo, getVideo, getVideoDetails, setVideoActivate, updateVideo } from '../services/video-service';

export default function useVideoMgtHook(path) {
    const [video_list, setVideoList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    useEffect(() => {
        fetchVideo()
    }, [])

    const fetchVideo = async () => {
        try {
            let res = await getVideo()
            let temp_list = []
            let index = 0
            let all_video = res.data.data
            for (let item of all_video) {
                temp_list.push({
                    id: item.video_id,
                    index: index + 1,
                    active: item.active,
                    video_categorys: item.video_categorys,
                    video_name: item.video_name,
                    video_body: item.video_body,
                    video_desc: item.video_desc,
                    video_thumbnail: item.video_thumbnail,
                    video_url: item.video_url,
                })
                index++
            }
            // console.log(temp_list);
            setVideoList(temp_list)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchVideoDetail = async (id) => {
        try {
            setData({
                ...form_data,
                "video_categorys": "",
                "video_name": "",
                "video_body": "",
                "video_desc":"",
                "video_thumbnail":"",
                "video_url":"",
                "active": 0
            })
            let res = await getVideoDetails(id)
            let video_data = res.data.data[0]
            setData({
                ['video_id']: video_data.video_id,
                ['video_categorys']: video_data.video_categorys,
                ['video_name']: video_data.video_name,
                ['video_body']: video_data.video_body,
                ['video_desc']: video_data.video_desc,
                ['video_thumbnail']: video_data.video_thumbnail,
                ['video_url']: video_data.video_url,
                ['active']: video_data.active,
            })
        } catch (error) {
            console.log(error);
        }
    }

    const onFileThumbnailChange = async (event, picture_id) => {
        if (event.target.files[0] !== undefined) {
            const formData = new FormData();
            formData.append(
                "file",
                event.target.files[0],
                event.target.files[0].name
            );
            try {
                let res_result = await uploadPicture(formData)
                handelDataChange(res_result.data.path, picture_id)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.video_name === '' || form_data.video_name === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลหัวข้อ Video \n กรุณากรอกข้อมูลหัวข้อ Video"
            }
        } else if (form_data.video_body === '' || form_data.video_body === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลเนื้อหา Video \n กรุณากรอกข้อมูลเนื้อหา Video"
            }
        } else if (form_data.video_desc === '' || form_data.video_desc === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลรายละเอียด Video \n กรุณากรอกข้อมูลรายละเอียด Video"
            }
        } else if (form_data.video_url === '' || form_data.video_url === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูล URL Video \n กรุณากรอกข้อมูล URL Video"
            }
        } else if (form_data.video_thumbnail === '' || form_data.video_thumbnail === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้เพิ่มรูป Video \n กรุณาเพิ่มรูป Video"
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        if (result.result) {
            let obj = {
                video_categorys: form_data.video_categorys,
                video_name: form_data.video_name,
                video_body: form_data.video_body,
                video_desc: form_data.video_desc,
                video_thumbnail: form_data.video_thumbnail,
                video_url: form_data.video_url,
                active: 0
            }
            try {
                if (mode === 'add') {
                    await addVideo(obj)
                    result = {
                        'result': true,
                        'msg': "เพิ่ม Video สำเร็จ"
                    }
                }else{
                    obj.video_id = form_data.video_id
                    obj.active = form_data.active
                    console.log(obj);
                    await updateVideo(obj)
                    result = {
                        'result': true,
                        'msg': "แก้ไข Video สำเร็จ"
                    }
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
                }
            }
        }
        return result
    } 

    const actionChangeStatus = async(video_id, status) => {
        let result
        try {
            await setVideoActivate({
                video_id: video_id,
                active: !status
            })
            result = {
                'result': true,
                'msg': "เปลี่ยนสถานะ Video สำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    const actionDelete = async (video_id) => {
        let result
        try {
            let obj = {
                'video_id': video_id
            }
            // console.log(obj);
            await deleteVideo(obj)
            result = {
                'result': true,
                'msg': "ลบ Video สำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    return {
        video_list,
        fetchVideo,
        form_data,
        handelDataChange,
        onFileThumbnailChange,
        actionSave,
        fetchVideoDetail,
        actionChangeStatus,
        actionDelete,
    }
}