import axios from 'axios';
import config from '../config.json';

export const login = async (obj) => {
    return axios.post(
        config.BASE_API + 'authen/login/',
        obj,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
}

export const changePassword = async (obj) => {
    return axios.post(
        config.BASE_API + 'authen/changePassword',
        obj,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
} 