import React, { useState, useEffect } from 'react';
import {
    addCategory,
    getAllCategorys,
    getDetailsCategorys,
    editCategory,
    deleteCategory
} from '../services/category-mgt-service';
import useFormDataHook from './form-data-hook';

export default function useCategoryMgtHook(path) {
    const [category_list, setCategoryList] = useState([])
    const [form_data, handelDataChange, clearData, setData] = useFormDataHook()

    useEffect(() => {
        fetchCategory()
    }, [])

    const fetchCategory = async () => {
        try {
            let res_result = await getAllCategorys()
            let all_categorys = res_result.data.data
            let temp_cat_list = []
            all_categorys.map((item, index) => {
                temp_cat_list.push({
                    id: item.category_id,
                    index: index + 1,
                    category_name: item.category_name,
                    status: item.category_active
                })
            })
            setCategoryList(temp_cat_list)
        } catch (error) {

        }
    }

    const fetchCategoryDetail = async (id) => {
        try {
            setData({
                ...form_data,
                'category_id': id,
                'category_name': '',
                'category_desc': '',
                'category_active': false,
            })
            let res = await getDetailsCategorys(id)
            let data = res.data.data[0]
            setData({
                ['category_id']: data.category_id,
                ['category_name']: data.category_name,
                ['category_desc']: data.category_desc,
                ['category_active']: data.category_active,
            })
        } catch (error) {
            console.log(error);
        }
    }

    const validateInput = (form_data) => {
        let result
        if (form_data.category_name === '' || form_data.category_name === undefined) {
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกข้อมูลชื่อสมาคม \n กรุณากรอกข้อมูลชื่อสมาคม"
            }
        } else {
            result = {
                'result': true,
                'msg': ""
            }
        }
        return result
    }

    const actionSave = async (mode) => {
        let result = validateInput(form_data)
        let obj = {
            "category_name": form_data.category_name,
            "category_desc": form_data?.category_desc === undefined ? "" : form_data.category_desc,
        }
        console.log(obj);
        if(result.result){
            try {
                if (mode === 'add') {
                    await addCategory(obj)
                    result = {
                        'result': true,
                        'msg': "เพิ่มสมาคมสำเร็จ"
                    }
                } else {
                    obj.category_id = form_data.category_id
                    obj.category_active = form_data.category_active
                    // console.log(obj);
                    await editCategory(obj)
                    result = {
                        'result': true,
                        'msg': "แก้ไขสมาคมสำเร็จ"
                    }
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
                }
            }

        }
        return result
    }

    const actionDelete = async (category_id) => {
        let result
        try {
            // console.log('category_id', category_id);
            let obj = {
                'category_id': category_id,
            }
            // console.log(obj);
            await deleteCategory(obj)
            result = {
                'result': true,
                'msg': "ลบสมาคมงานสำเร็จ"
            }
        } catch (error) {
            console.log(error);
            result = {
                'result': false,
                'msg': "เกิดข้อผิดพลาดกรุณาติดต่อผู้พัฒนา"
            }
        }
        return result
    }

    return {
        category_list,
        fetchCategory,
        form_data,
        handelDataChange,
        actionSave,
        fetchCategoryDetail,
        actionDelete,
    }
}