import React, { useState, useEffect } from 'react';

export default function useFormDataHook (path) {

    const [data, setData] = useState([])

    const handelDataChange = (value, id) => {
        setData({
            ...data,
            [id]: value
        })
    }

    const clearData = () => {
        setData([])
    }

    return [data, handelDataChange, clearData, setData]
}