import React, { useState, useEffect } from 'react';
import { changePassword, login } from '../services/auth-service';
import localStorage from '../utils/local-storage';
import { jwtDecode } from "jwt-decode";

export default function useAuthHook(path) {

    const [menu_list, setMenuList] = useState([])
    const actionSignIn = async (form_data) => {
        let obj = {
            "Username": form_data.username,
            "Password": form_data.password
        }
        try {
            let login_res = await login(obj);
            localStorage.setToken(login_res.data.data[0]['accessToken'])
            localStorage.setMenu(login_res.data.data[0]['menus'])
            // console.log(login_res.data.data[0]);
            window.location.href = "/" + login_res.data.data[0].homepage_uri;
            return true
        } catch (error) {
            return false
        }
    }

    const actionSignOut = async () => {
        localStorage.removeToken();
    }

    const actionChangePassword = async (form_data) => {
        let result
        if(form_data.length < 3){
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกรหัสเดิม กรุณากรอกรหัสเดิมใหม่"
            }
        } else if(form_data.old_password === ""){
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกรหัสเดิม กรุณากรอกรหัสเดิมใหม่"
            }
        } else if(form_data.new_password === ""){
            result = {
                'result': false,
                'msg': "คุณยังไม่ได้กรอกรหัสใหม่ กรุณากรอกรหัสใหม่"
            }
        } else if(form_data.new_password !== form_data.cfm_password){
            result = {
                'result': false,
                'msg': "password ใหม่ และ ยืนยัน password ใหม่ไม่เหมือนกัน กรุณากรอกรหัสใหม่"
            }
        }else{
            try {
                let token = localStorage.getToken()
                let decoded_token = jwtDecode(token)
                let obj = {
                    user_id: decoded_token.id,
                    password_old: form_data.old_password,
                    password_new: form_data.new_password
                }
                await changePassword(obj)
                result = {
                    'result': true,
                    'msg': "เปลี่ยนรหัสผ่านสำเร็จ"
                }
            } catch (error) {
                console.log(error);
                result = {
                    'result': false,
                    'msg': "คุณกรอกรหัสผ่านเดิมไม่ถูกต้อง กรุณากรอกรหัสเดิมใหม่"
                }
            }
        }
        return result
    }

    const fetchMenuList = async () => {
        setMenuList(
            JSON.parse(localStorage.getMenu())
        )
    }


    return { actionSignIn, actionSignOut, fetchMenuList, menu_list, actionChangePassword }
}