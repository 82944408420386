import React, { useState, useEffect } from 'react';
import {
    Container,
} from '@mui/material';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import NewsForm from '../../components/form/news-form';

export default function NewsDetail() {
    return(
        <>
            <AdminMenu/>
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Edit News"}
                    is_main_page={false}
                />
                <NewsForm
                    mode={'edit'}
                    button_title={'Edit'}
                />
            </Container>
        </>
    )
}