import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    CardActions,
    Button
} from '@mui/material';
import useBannerMgtHook from '../../hooks/banner-mgt-hook';


export default function BannerForm({ mode, button_title }) {
    const { id } = useParams();
    const { form_data, handelDataChange, onFileThumbnailChange, actionSave, fetchBannerDetail } = useBannerMgtHook()

    const handleSaveButton = async () => {
        let result = await actionSave(mode)
        alert(result.msg)
        if (result.result) {
            window.location.href = "/admin/banner";
        }
    }

    useEffect(() => {
        const asyncFn = async () => {
            if (mode === 'edit') {
                await fetchBannerDetail(id)
            }
        }
        asyncFn()
    }, [])

    return (
        <>
            <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="banner_subject"
                                label="หัวข้อ Banner"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.banner_subject}
                                onChange={(e) => handelDataChange(e.target.value, 'banner_subject')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="banner_body"
                                label="เนื้อหา Banner"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.banner_body}
                                onChange={(e) => handelDataChange(e.target.value, 'banner_body')}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                id="banner_image"
                                label="รูป Banner สำหรับอุปกรณ์ทั่วไป"
                                onChange={event => onFileThumbnailChange(event, "banner_image")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    backgroundColor: "#FFFFFF"
                                }}
                                type="file"
                                inputProps={{ accept: 'image/*' }}
                            />
                        </Grid>
                        {
                            form_data.banner_image !== undefined && form_data.banner_image !== '' ?
                                <Grid item xs={12}>
                                    <img
                                        alt="Logo"
                                        src={form_data.banner_image}
                                        style={{ height: 400 }}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                id="banner_link"
                                label="รูป Banner สำหรับ Tablet"
                                onChange={event => onFileThumbnailChange(event, "banner_link")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    backgroundColor: "#FFFFFF"
                                }}
                                type="file"
                                inputProps={{ accept: 'image/*' }}
                            />
                        </Grid>
                        {
                            form_data.banner_link !== undefined && form_data.banner_link !== '' ?
                                <Grid item xs={12}>
                                    <img
                                        alt="Logo"
                                        src={form_data.banner_link}
                                        style={{ height: 400 }}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                id="banner_thumbnail"
                                label="รูป Banner สำหรับมือถือ"
                                onChange={event => onFileThumbnailChange(event, "banner_thumbnail")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    backgroundColor: "#FFFFFF"
                                }}
                                type="file"
                                inputProps={{ accept: 'image/*' }}
                            />
                        </Grid>
                        {
                            form_data.banner_thumbnail !== undefined && form_data.banner_thumbnail !== '' ?
                                <Grid item xs={12}>
                                    <img
                                        alt="Logo"
                                        src={form_data.banner_thumbnail}
                                        style={{ height: 400 }}
                                    />
                                </Grid>
                                : null
                        }
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item ></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveButton}
                            >
                                {button_title}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { window.location.href = "/admin/banner"; }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    )
}