import React, { useState, useEffect } from 'react';
import {
    Grid,
    Container,
    FormGroup,
    FormControlLabel,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import useCategoryMgtHook from '../../hooks/category-mgt-hook';
import useDialogBoxHook from '../../hooks/dialog-box-hook';
import CustomDialogBox from '../../components/custom-dialog-box';

export default function CategoryList() {
    const { category_list, actionDelete, fetchCategory } = useCategoryMgtHook()

    const {
        is_dialog_box_open,
        dialog_box_header,
        dialog_box_body,
        dialog_box_action,
        setDialogBoxOption
    } = useDialogBoxHook()

    const [selected_id, setSelectedId] = useState(0)

    const columns = [
        { field: 'index', headerName: 'ลำดับ', maxWidth: 100, flex: 1 },
        { field: 'category_name', headerName: 'ชื่อสมาคม', minWidth: 250, flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <EditIcon />
                </IconButton>
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleDeleteClick(event, cellValues);
                    }} >
                    <DeleteIcon />
                </IconButton>
            }
        }
    ];

    const handleAddClick = (event) => {
        event.preventDefault();
        window.location.href = "/admin/category/add";
    }

    const handleEditClick = (event, cellValues) => {
        event.preventDefault();
        window.location.href = "/admin/category/edit/" + cellValues.row.id
    }

    const handleDeleteClick = (event, cellValues) => {
        event.preventDefault();
        let category_id = cellValues.row.id
        let category_name = cellValues.row.category_name
        setSelectedId(category_id)
        setDialogBoxOption(
            true,
            "ลบสมาคม",
            "คุณต้องการลบช้อมูลสมาคม " + category_name + " หรือไม่",
            "delete"
        )
    }

    const handleDialogClose = (event) => {
        setDialogBoxOption(
            false,
            "",
            "",
            ""
        )
        setSelectedId(0)
    }

    const handleSubmitButton = async () => {
        let result
        // console.log('selected_id', selected_id);
        result = await actionDelete(selected_id)
        alert(result.msg)
        if (result.result) {
            fetchCategory()
            handleDialogClose()
        }
    }

    return (
        <>
            <AdminMenu />
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Category Management"}
                    button_title={"Add Category"}
                    handleBtnClick={handleAddClick}
                    is_main_page={true}
                />
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                            <DataGrid
                                style={{ backgroundColor: "#FFFFFF" }}
                                rows={category_list}
                                columns={columns}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <CustomDialogBox
                open={is_dialog_box_open}
                handleCancleButton={handleDialogClose}
                header={dialog_box_header}
                body={dialog_box_body}
                handleSubmitButton={handleSubmitButton}
            />
        </>
    )
}