import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    MenuItem,
    Menu
} from '@mui/material'
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import useAuthHook from '../hooks/auth-hook';
import ChangePasswordModal from './form/change-password-form';

export default function AdminMenu() {

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [is_change_password_open, setIsChangePasswordOpen] = useState(false)

    const handleChangePasswordClick = () => {
        setIsChangePasswordOpen(true)
      }
    
      const handleChangePasswordClose = () => {
        setIsChangePasswordOpen(false)
      }

    const { menu_list, fetchMenuList, actionSignOut } = useAuthHook()

    const handleSignOut = () => {
        actionSignOut()
        window.location.href = "/";
    }

    useEffect(()=> {
        fetchMenuList()
    },[])

    return (
        <>
            <AppBar>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <AdbIcon sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            // href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', sm: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            TSP Admin
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                }}
                            >
                                {menu_list.map((page, index) => (
                                    <MenuItem
                                        component='a'
                                        key={index}
                                    // herf={page.menu_uri}
                                    // onClick={(event) => handlePagechange(event, page.menu_uri)}
                                    >
                                        <Typography textAlign="center">{page.menu_name}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    onClick={handleSignOut} 
                                >
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <AdbIcon sx={{ display: { xs: 'flex', sm: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            TSP Admin
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                            {menu_list.map((page, index) => (
                                <Button
                                    key={index}
                                    href={page.menu_uri}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.menu_name}
                                </Button>
                            ))}
                            <Button
                                // key={4}
                                // href={page.menu_uri} 
                                onClick={handleChangePasswordClick}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Change Password
                            </Button>
                            <Button
                                // key={4}
                                // href={page.menu_uri} 
                                onClick={handleSignOut}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                LOGOUT
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <ChangePasswordModal 
                is_open={is_change_password_open}
                handleCloseModal={handleChangePasswordClose}
            />
        </>
    )
}