import React, { useState, useEffect } from 'react';
import useFormDataHook from './form-data-hook';
import { getAllCategorys } from '../services/category-mgt-service';
import { getNewsbyCategoryID } from '../services/news-service';

export default function useNewApprovalMgtHook(path) {
    const [news_list, setNewsList] = useState([])
    useEffect(() => {
        fetchNews()
    }, [])
    const fetchNews = async () => {
        try {
            let category_list = await getAllCategorys()

            let temp_list = []
            let index = 1;
            for (let category_item of category_list.data.data) {
                let news_data = await getNewsbyCategoryID(category_item.category_id)
                for (let item of news_data.data.data) {
                    temp_list.push({
                        id: item.news_id,
                        index: index++,
                        news_subject: item.news_subject,
                        news_status: item.news_status,
                        approved_name: item.approved_name,
                        category_name: item.category_name,
                        createdAt: item.createdAt
                    })
                }
            }
            temp_list.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                // console.log(new Date(a.createdAt));
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            // console.log(temp_list);
            setNewsList(temp_list)
        } catch (error) {
            console.log(error);
        }
    }

    return {
        news_list,
        fetchNews
    }
}