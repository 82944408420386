import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    CardActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography
} from '@mui/material';
import useNewsMgtHook from '../../hooks/news-mgt-hook';
import useCategoryMgtHook from '../../hooks/category-mgt-hook';
import JoditEditor from "jodit-react";


export default function NewsForm({ mode, button_title }) {

    const { id } = useParams();

    const { form_data, handelDataChange, onFileThumbnailChange, actionSave, fetchNewsDetail } = useNewsMgtHook()
    const { category_list } = useCategoryMgtHook()

    const handleSaveButton = async () => {
        let result = await actionSave(mode)
        alert(result.msg)
        if (result.result) {
            if(mode !== 'view'){
                window.location.href = "/admin/journalist";
            }else{
                window.location.href = "/admin/lead";
            }
        }
    }

    useEffect(() => {
        const asyncFn = async () => {
            if (mode === 'edit' || mode === 'view') {
                await fetchNewsDetail(id)
            }
        }
        asyncFn()
    }, [])
    return (
        <>
            <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8} lg={8}>
                            <TextField
                                fullWidth
                                id="news_subject"
                                label="พาดหัวข่าว"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.news_subject}
                                onChange={(e) => handelDataChange(e.target.value, 'news_subject')}
                                disabled={mode === 'view' ? true : false}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">สมาคม</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={form_data.category_id === undefined ? 0 : form_data.category_id}
                                    label="สมาคม"
                                    onChange={(event) => {
                                        handelDataChange(event.target.value, 'category_id');
                                    }}
                                    disabled={mode === 'view' ? true : false}
                                >
                                    <MenuItem value={0}>กรุณาเลือกสมาคม</MenuItem>
                                    {
                                        category_list.map((item, index) => {
                                            return (<MenuItem key={index + 1} value={item.id}>{item.category_name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            {
                                mode !== 'view' ?
                                    <TextField
                                        fullWidth
                                        id="news_thumbnail"
                                        label="รูปหน้าแรก"
                                        onChange={event => onFileThumbnailChange(event, "news_thumbnail")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            backgroundColor: "#FFFFFF"
                                        }}
                                        type="file"
                                        inputProps={{ accept: 'image/*' }}
                                    />
                                    : <Typography variant='body' style={{ margin: '1rem' }}>รูปหน้าแรก</Typography>
                            }
                        </Grid>
                        {
                            form_data.news_thumbnail !== undefined && form_data.news_thumbnail !== '' ?
                                <Grid item xs={12}>
                                    <img
                                        alt="Logo"
                                        src={form_data.news_thumbnail}
                                        style={{ height: 200 }}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} >
                            {
                                mode !== 'view' ?

                                    <TextField
                                        fullWidth
                                        id="news_image"
                                        label="รูป Banner ข่าว"
                                        onChange={event => onFileThumbnailChange(event, "news_image")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            backgroundColor: "#FFFFFF"
                                        }}
                                        type="file"
                                        inputProps={{ accept: 'image/*' }}
                                    />
                                    : <Typography variant='body' style={{ margin: '1rem' }}>รูป Banner ข่าว</Typography>
                            }
                        </Grid>
                        {
                            form_data.news_image !== undefined && form_data.news_image !== '' ?
                                <Grid item xs={12}>
                                    <img
                                        alt="Logo"
                                        src={form_data.news_image}
                                        style={{ height: 200 }}
                                    />
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} >
                            {
                                mode !== 'view' ?
                                    <TextField
                                        fullWidth
                                        id="news_image_body"
                                        label="รูปในเนื้อหาข่าว"
                                        onChange={event => onFileThumbnailChange(event, "news_image_body")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            backgroundColor: "#FFFFFF"
                                        }}
                                        type="file"
                                        inputProps={{ accept: 'image/*' }}
                                    />
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' sx={{ pb: 0 }}>
                                เนื้อหาข่าว
                            </Typography>
                            <JoditEditor
                                ref={useRef(null)}
                                value={form_data.news_body}
                                config={{
                                    readonly: false // all options from https://xdsoft.net/jodit/doc/
                                }}
                                tabIndex={7} // tabIndex of textarea
                                onBlur={newContent => handelDataChange(newContent, 'news_body')} // preferred to use only this option to update the content for performance reasons
                                onChange={newContent => { }}
                                toolbarButtonSize={"small"}
                                sx={{
                                    backgroundColor: "#FFFFFF",
                                    minHeight: 500
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            {
                                mode === 'view' ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">สถานะข่าว</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={form_data.news_status === undefined ? 0 : form_data.news_status}
                                            label="สถานะข่าว"
                                            onChange={(event) => {
                                                handelDataChange(event.target.value, 'news_status');
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>กรุณาเลือกสถานะข่าว</MenuItem>
                                            <MenuItem key={1} value={1}>อนุมัติ</MenuItem>
                                            <MenuItem key={2} value={2}>ไม่อนุมัติ แก้ไขพาดหัวข่าว</MenuItem>
                                            <MenuItem key={3} value={3}>ไม่อนุมัติ แก้ไขเนื้อหาข่าว</MenuItem>
                                            <MenuItem key={4} value={4}>ไม่อนุมัติ แก้ไขรูปหน้าปก</MenuItem>
                                            <MenuItem key={5} value={5}>ไม่อนุมัติ แก้ไขรูปหน้าแรก</MenuItem>
                                        </Select>
                                    </FormControl>
                                    : null
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveButton}
                            >
                                {button_title}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { window.location.href = "/admin/journalist"; }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    )
}