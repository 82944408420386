import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getNewsbyCreatedID = async (id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'news?createdBy=' + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getNewsbyCategoryID = async (id) => {
    let token = LocalStorage.getToken();  
    return axios.get(
      config.BASE_API + 'news?category_id='+id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+token
        }
      }
    )
  }

export const getNewsbyNewsID = async (id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'news/' + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addNews = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'news',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updateNews = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(obj);  
    return axios.put(
        config.BASE_API + 'news',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const deleteNews = async (obj) => {
    let token = LocalStorage.getToken();
    // console.log(token);
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsIm5hbWUiOiJhZG1pbiIsImZpcnN0X25hbWUiOiJhZG1pbiIsImxhc3RfbmFtZSI6ImFkbWluIiwicm9sZV9pZCI6MSwiaWF0IjoxNjY4NjE4Njk5LCJleHAiOjE2Njg3MDUwOTl9.dah8UjEttEPMw4RIGQwmEiF282r7J1uABX95Cn7VSac"
    return axios.post(
      config.BASE_API + 'news/delete',
      obj,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+token
        }
      }
    )
  }

  export const approveNews = async (obj) => {
  let token = LocalStorage.getToken();
  // console.log(obj);  
  return axios.put(
    config.BASE_API + 'news/approve',
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      }
    }
  )
}