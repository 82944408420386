import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    CardActions,
    Button
} from '@mui/material';
import useLiveMgtHook from '../../hooks/live-mgt-hook';
import ReactPlayer from 'react-player'


export default function LiveForm({ mode, button_title }) {
    const { id } = useParams();

    const { form_data, handelDataChange, onFileThumbnailChange, actionSave, fetchLiveStreamDetail } = useLiveMgtHook()
    const handleSaveButton = async () => {
        let result = await actionSave(mode)
        alert(result.msg)
        if (result.result) {
            window.location.href = "/admin/live";
        }
    }

    useEffect(() => {
        const asyncFn = async () => {
            if (mode === 'edit') {
                await fetchLiveStreamDetail(id)
            }
        }
        asyncFn()
    }, [])
    return (
        <>
            <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="streaming_name"
                                label="ชื่อ Live"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.streaming_name}
                                onChange={(e) => handelDataChange(e.target.value, 'streaming_name')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="streaming_tags"
                                label="ช่องทาง Live"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.streaming_tags}
                                onChange={(e) => handelDataChange(e.target.value, 'streaming_tags')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="รูปหน้าแรก"
                                onChange={event => onFileThumbnailChange(event)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    backgroundColor: "#FFFFFF"
                                }}
                                type="file"
                                inputProps={{ accept: 'image/*' }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {
                                form_data.streaming_thumbnail !== undefined && form_data.streaming_thumbnail !== '' ?
                                    <img
                                        alt="Logo"
                                        src={form_data.streaming_thumbnail}
                                        style={{ width: '100%' }}
                                    /> : null
                            }
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="streaming_desc"
                                label="URL Live (Cloudflare)"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.streaming_desc}
                                onChange={(e) => handelDataChange(e.target.value, 'streaming_desc')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {
                                form_data.streaming_desc !== undefined && form_data.streaming_desc !== '' ?
                                    <div dangerouslySetInnerHTML={{ __html: form_data.streaming_desc }} /> : null
                            }
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                fullWidth
                                id="streaming_url"
                                label="URL Live (m3u8)"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.streaming_url}
                                onChange={(e) => handelDataChange(e.target.value, 'streaming_url')}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {
                                form_data.streaming_url !== undefined && form_data.streaming_url !== '' ?
                                    <ReactPlayer
                                        className='react-player'
                                        url={form_data.streaming_url}
                                        width='100%'
                                        // height='500px'
                                        // playing={play}
                                        controls={true}
                                    /> : null
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item ></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveButton}
                            >
                                {button_title}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { window.location.href = "/admin/live"; }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    )
}