import React, { useState, useEffect } from 'react';
import {
    Grid,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import CustomDialogBox from '../../components/custom-dialog-box';
import useDialogBoxHook from '../../hooks/dialog-box-hook';
import useBannerMgtHook from '../../hooks/banner-mgt-hook';

export default function BannerList() {

    const {
        banner_list, 
        fetchBanner, 
        actionChangeStatus, 
        actionDelete
    } = useBannerMgtHook()

    const {
        is_dialog_box_open,
        dialog_box_header,
        dialog_box_body,
        dialog_box_action,
        setDialogBoxOption
    } = useDialogBoxHook()

    const [selected_id, setSelectedId] = useState(0)
    const [selected_data, setSelectedData] = useState(false)

    const columns = [
        { field: 'index', headerName: 'ลำดับ', maxWidth: 50, flex: 1 },
        { field: 'banner_subject', headerName: 'ชื่อ Banner', minWidth: 150, flex: 1 },
        {
            field: "active",
            headerName: "Activated",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={cellValues.row.active}
                                onClick={(event) => {
                                    handleStatusSwitch(event, cellValues);
                                }}
                            />
                        }
                        label=""
                    />
                </FormGroup>
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <EditIcon />
                </IconButton>
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleDeleteClick(event, cellValues);
                    }} >
                    <DeleteIcon />
                </IconButton>
            }
        }
    ];

    const handleAddClick = (event) => {
        event.preventDefault();
        window.location.href = "/admin/banner/add"
    }

    const handleEditClick = (event, cellValues) => {
        event.preventDefault();
        window.location.href = "/admin/banner/edit/"+cellValues.row.id
    }

    const handleDeleteClick = (event, cellValues) => {
        event.preventDefault();
        let banner_id = cellValues.row.id
        let banner_subject = cellValues.row.banner_subject
        setSelectedId(banner_id)
        setDialogBoxOption(
            true,
            "ลบ Banner",
            "คุณต้องการลบช้อมูล Banner " + banner_subject + " หรือไม่",
            "delete"
        )
    }

    const handleStatusSwitch = (event, cellValues) => {
        event.preventDefault();
        let banner_id = cellValues.row.id
        let banner_subject = cellValues.row.banner_subject
        console.log(cellValues.row);
        setSelectedId(banner_id)
        setSelectedData(cellValues.row)
        setDialogBoxOption(
            true,
            "เปลี่ยนสถานะ Banner",
            "คุณต้องการเปลี่ยนสถานะ Banner " + banner_subject + " หรือไม่",
            "change_status"
        )
    }

    const handleDialogClose = (event) => {
        setDialogBoxOption(
            false,
            "",
            "",
            ""
        )
        setSelectedId(0)
    }

    const handleSubmitButton = async () => {
        let result
        if (dialog_box_action === 'change_status') {
            result = await actionChangeStatus(selected_data)
        } else if (dialog_box_action === 'delete') {
            result = await actionDelete(selected_id)
        }
        alert(result.msg)
        if (result.result) {
            fetchBanner()
            handleDialogClose()
        }
    }

    return (
        <>
            <AdminMenu />
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Banner Management"}
                    button_title={"Add Banner"}
                    handleBtnClick={handleAddClick}
                    is_main_page={true}
                />
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                            <DataGrid
                                style={{ backgroundColor: "#FFFFFF" }}
                                rows={banner_list}
                                columns={columns}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <CustomDialogBox
                open={is_dialog_box_open}
                handleCancleButton={handleDialogClose}
                header={dialog_box_header}
                body={dialog_box_body}
                handleSubmitButton={handleSubmitButton}
            />
        </>
    )
}