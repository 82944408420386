import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/local-storage';

export const getBannerList = async () => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'banners',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getBannerDetails = async (banner_id) => {
    let token = LocalStorage.getToken();
    return axios.get(
        config.BASE_API + 'banners/' + banner_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addBanner = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'banners',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updateBanner = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.put(
        config.BASE_API + 'banners',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const deleteBanner = async (obj) => {
    let token = LocalStorage.getToken();
    return axios.post(
        config.BASE_API + 'banners/delete',
        obj,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
} 