import React, { useState, useEffect } from 'react';

export default function useDialogBoxHook(path) {
    const [is_dialog_box_open, setIsDialogBoxOpen] = useState(false)
    const [dialog_box_header, setDialogBoxHeader] = useState("")
    const [dialog_box_body, setDialogBoxBody] = useState("")
    const [dialog_box_action, setDialogBoxAction] = useState("")

    const setDialogBoxOption = (
        open,
        header,
        body,
        action
    ) => {
        setIsDialogBoxOpen(open)
        setDialogBoxHeader(header)
        setDialogBoxBody(body)
        setDialogBoxAction(action)
    }

    return {
        is_dialog_box_open, 
        dialog_box_header, 
        dialog_box_body, 
        dialog_box_action,
        setDialogBoxOption
    }
}