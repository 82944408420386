import React, { useState, useEffect } from 'react';
import {
    Container,
} from '@mui/material';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import CategoryForm from '../../components/form/category-form';

export default function CategoryDetail() {
    return(
        <>
            <AdminMenu/>
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"Category Detail"}
                    is_main_page={false}
                />
                <CategoryForm
                    mode={'edit'}
                    button_title={'Save'}
                />
            </Container>
        </>
    )
}