import React, { useState, useEffect } from 'react';
import {
    Grid,
    Container,
    FormGroup,
    FormControlLabel,
    Switch,
    IconButton
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { DataGrid } from '@mui/x-data-grid';
import AdminMenu from '../../components/menu';
import BoFTitle from '../../components/bof-title';
import useNewsMgtHook from '../../hooks/news-mgt-hook';
import useNewApprovalMgtHook from '../../hooks/news-approval-mgt-hook';

export default function NewsApprovalList() {
    const columns = [
        { field: 'id', headerName: 'รหัสข่าว', maxWidth: 120, flex: 1 },
        { field: 'news_subject', headerName: 'พาดหัวข่าว', minWidth: 450, flex: 1 },
        { field: 'news_status', headerName: 'สถานะการ Approve', minWidth: 200, flex: 1 },
        { field: 'category_name', headerName: 'สมาคม', minWidth: 150, flex: 1 },
        {
            field: "preview",
            headerName: "Preview",
            sortable: false,
            minWidth: 150,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <PreviewIcon />
                </IconButton>
            }
        }
    ];

    const {
        news_list,
    } = useNewApprovalMgtHook()

    const handleEditClick = (event, cellValues) => {
        event.preventDefault();
        window.location.href = "/admin/lead/view/" + cellValues.row.id
    }

    return (
        <>
            <AdminMenu />
            <Container maxWidth="lg" style={{ marginTop: '7rem' }}>
                <BoFTitle
                    title={"News Approval Management"}
                    is_main_page={false}
                />
                <Grid container>
                    <Grid item xs={12} >
                        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                            <DataGrid
                                style={{ backgroundColor: "#FFFFFF" }}
                                rows={news_list}
                                columns={columns}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}