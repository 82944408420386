function setToken(token) {
    localStorage.setItem('ACCESS_TOKEN', token);
}

function setMenu(menu) {
    localStorage.setItem('MENU', JSON.stringify(menu));
}

function setUserID(user_id) {
    localStorage.setItem('USER_ID', user_id);
  }

function getToken() {
    return localStorage.getItem('ACCESS_TOKEN');
}

function getMenu() {
    return localStorage.getItem('MENU');
}

function getUserId() {
    return localStorage.getItem('USER_ID');
}

function removeToken() {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('MENU');
    localStorage.removeItem('USER_ID');
}

export default {
    setToken,
    getToken,
    removeToken,
    setMenu,
    getMenu,
    setUserID,
    getUserId,
};
