import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    TextField,
    CardActions,
    Button
} from '@mui/material';
import useCategoryMgtHook from '../../hooks/category-mgt-hook';


export default function CategoryForm({ mode, button_title }) {
    const { id } = useParams();
    const {form_data, handelDataChange, actionSave, fetchCategoryDetail} = useCategoryMgtHook()

    const handleSaveButton = async () => {
        let result = await actionSave(mode)
        alert(result.msg)

        if (result.result) {
            window.location.href = "/admin/category";
        }
    }

    useEffect(() => {
        const asyncFn = async () => {
            if (mode === 'edit') {
                await fetchCategoryDetail(id)
            }
        }
        asyncFn()
    }, [])

    return (
        <>
            <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="category_name"
                                label="ชื่อสมาคม"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.category_name}
                                onChange={(e) => handelDataChange(e.target.value, 'category_name')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="category_desc"
                                label="คำอธิบายสมาคม"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.category_desc}
                                onChange={(e) => handelDataChange(e.target.value, 'category_desc')}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item ></Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveButton}
                            >
                                {button_title}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => { window.location.href = "/admin/category"; }}
                            >
                                BACK
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    )
} 